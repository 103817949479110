import { default as indexw1xRrKhE47Meta } from "/var/www/sites/kitchen.ultrafedt.dk/releases/20250124223345/pages/index.vue?macro=true";
import { default as _91merchantId_939fTmKkLOjdMeta } from "/var/www/sites/kitchen.ultrafedt.dk/releases/20250124223345/pages/setup/[merchantId].vue?macro=true";
import { default as indexSmFqehowXpMeta } from "/var/www/sites/kitchen.ultrafedt.dk/releases/20250124223345/pages/setup/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/kitchen.ultrafedt.dk/releases/20250124223345/pages/index.vue")
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    component: () => import("/var/www/sites/kitchen.ultrafedt.dk/releases/20250124223345/pages/setup/[merchantId].vue")
  },
  {
    name: "setup",
    path: "/setup",
    component: () => import("/var/www/sites/kitchen.ultrafedt.dk/releases/20250124223345/pages/setup/index.vue")
  }
]